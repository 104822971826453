/*
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 14:35:09
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-24 21:51:58
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  { // 审核端
    path: '/review',
    component: () => import('../views/Review/App.vue'),
    meta: {
      title: '奕侨维修区、工程部审核端'
    },
    children: [
      // 首页
      {
        path: '',
        name: 'ReviewIndex',
        component: () => import('../views/Review/views/Index.vue'),
        meta: {
          title: '奕侨维修区、工程部审核端'
        },
      },

      // 登录
      {
        path: '/review/login',
        name: 'ReviewLogin',
        component: () => import('../views/Review/views/Login.vue'),
        meta: {
          title: '奕侨维修区、工程部审核端'
        },
      },

      // 忘记密码
      {
        path: '/review/forget',
        name: 'ReviewForget',
        component: () => import('../views/Review/views/Forget.vue'),
        meta: {
          title: '忘记密码'
        },
      },

      // 工单列表
      {
        path: '/review/orderList',
        name: 'ReviewOrderList',
        component: () => import('../views/Review/views/OrderList.vue'),
        meta: {
          title: '工单列表'
        },
      },

      // 工单详情
      {
        path: '/review/orderDetail',
        name: 'ReviewOrderDetail',
        component: () => import('../views/Review/views/OrderDetail.vue'),
        meta: {
          title: '详情页'
        },
      },

      // 工单报价单
      {
        path: '/review/offer',
        name: 'ReviewOffer',
        component: () => import('../views/Review/views/Offer.vue'),
        meta: {
          title: '报价单'
        },
      },

      // 在线报修
      {
        path: '/review/repair',
        name: 'ReviewRepair',
        component: () => import('../views/Review/views/Repair.vue'),
        meta: {
          title: '在线报修'
        },
      },

      // 个人中心
      {
        path: '/review/mine',
        name: 'ReviewMine',
        component: () => import('../views/Review/views/Mine.vue'),
        meta: {
          title: '个人中心'
        },
      },

      // 草稿箱
      {
        path: '/review/draftBox',
        name: 'ReviewDraftBox',
        component: () => import('../views/Review/views/DraftBox.vue'),
        meta: {
          title: '草稿箱'
        },
      },

      // 我的工单列表
      {
        path: '/review/mineOrderList',
        name: 'ReviewMineOrderList',
        component: () => import('../views/Review/views/MineOrderList.vue'),
        meta: {
          title: '工单列表'
        },
      },

      // 我的工单详情
      {
        path: '/review/mineOrderDetail',
        name: 'ReviewMineOrderDetail',
        component: () => import('../views/Review/views/MineOrderDetail.vue'),
        meta: {
          title: '工单详情'
        },
      },

      // 支付
      {
        path: '/review/pay',
        name: 'ReviewPay',
        component: () => import('../views/Review/views/Pay.vue'),
        meta: {
          title: '工单详情'
        },
      },
    ]
  },

  { // 用户端
    path: "/user",
    component: () => import('../views/User/App.vue'),
    meta: {
      title: '我的'
    },
    children: [
      // 首页
      {
        path: '/user/index',
        name: 'UserIndex',
        component: () => import('../views/User/views/Index.vue'),
        meta: {
          title: '我的'
        },
      },

      // 在线报修
      {
        path: '',
        name: 'UserRepair',
        component: () => import('../views/User/views/Repair.vue'),
        meta: {
          title: '在线报修'
        },
      },

      // 待支付
      {
        path: '/user/pay',
        name: 'UserPay',
        component: () => import('../views/User/views/Pay.vue')
      },

      // 待审核
      {
        path: '/user/waitReview',
        name: 'UserWaitReview',
        component: () => import('../views/User/views/WaitReview.vue')
      },

      // 草稿箱
      {
        path: '/user/draftBox',
        name: 'UserDraftBox',
        component: () => import('../views/User/views/DraftBox.vue'),
        meta: {
          title: '草稿箱'
        },
      },

      // 工单列表
      {
        path: '/user/orderList',
        name: "UserOrderList",
        component: () => import('../views/User/views/OrderList.vue'),
        meta: {
          title: '工单列表'
        },
      },

      // 工单详情
      {
        path: '/user/orderDetail',
        name: "UserOrderDetail",
        component: () => import('../views/User/views/OrderDetail.vue'),
        meta: {
          title: '工单详情'
        },
      },

      // 报价单
      {
        path: '/user/offer',
        name: "UserOffer",
        component: () => import('../views/User/views/Offer.vue'),
        meta: {
          title: '报价单'
        },
      }
    ]
  },

  { // 维修端
    path: "/service",
    component: () => import('../views/Service/App.vue'),
    meta: {
      title: '奕侨师傅维修端'
    },
    children: [
      // 首页
      {
        path: '',
        name: 'ServiceIndex',
        component: () => import('../views/Service/views/Index.vue'),
        meta: {
          title: '奕侨师傅维修端'
        },
      },

      // 登陆
      {
        path: '/service/login',
        name: 'ServiceLogin',
        component: () => import('../views/Service/views/Login.vue'),
        meta: {
          title: '奕侨师傅维修端'
        },
      },

      // 忘记密码
      {
        path: '/service/forget',
        name: 'ServiceForget',
        component: () => import('../views/Service/views/Forget.vue'),
        meta: {
          title: '忘记密码'
        },
      },

      // 注册
      {
        path: '/service/register',
        name: 'ServiceRegister',
        component: () => import('../views/Service/views/Register.vue'),
        meta: {
          title: '注册'
        },
      },

      // 报价单
      {
        path: '/service/offer',
        name: 'ServiceOffer',
        component: () => import('../views/Service/views/Offer.vue'),
        meta: {
          title: '报价单'
        },
      },

      // 提交报告
      {
        path: '/service/report',
        name: 'ServiceReport',
        component: () => import('../views/Service/views/Report.vue'),
        meta: {
          title: '提交报告'
        },
      },

      // 工单列表
      {
        path: '/service/orderList',
        name: 'ServiceOrderList',
        component: () => import('../views/Service/views/OrderList.vue'),
        meta: {
          title: '工单列表'
        },
      },

      // 工单详情
      {
        path: '/service/orderDetail',
        name: "ServiceOrderDetail",
        component: () => import('../views/Service/views/OrderDetail.vue'),
        meta: {
          title: '工单详情'
        },
      },

      // 待接单
      {
        path: '/service/waitingOrder',
        name: 'ServiceWaitingOrder',
        component: () => import('../views/Service/views/WaitingOrder.vue')
      },

      // 待报价
      {
        path: '/service/waitingQuote',
        name: 'ServiceWaitingQuote',
        component: () => import('../views/Service/views/WaitingQuote.vue')
      },

      // 待维修
      {
        path: '/service/waitingService',
        name: 'ServiceWaitingService',
        component: () => import('../views/Service/views/WaitingService.vue')
      },

      // 维修完成
      {
        path: '/service/completeService',
        name: 'ServiceCompleteService',
        component: () => import('../views/Service/views/CompleteService.vue')
      },

      // 待结算
      {
        path: '/service/waitingSettle',
        name: 'ServiceWaitingSettle',
        component: () => import('../views/Service/views/WaitingSettle.vue')
      },

      // 超时
      {
        path: '/service/timeout',
        name: 'ServiceTimeout',
        component: () => import('../views/Service/views/Timeout.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router