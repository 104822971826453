/*
 * @Descripttion: 获取授权token
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-09 22:08:14
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-02 14:08:11
 */

import router from '../router'

import {
    auth
} from "./api/utils"

import isWeixin from "./checkWxBrowser"

export default function getAuth() {
    const mainPath = router.history.current.fullPath.split('/')[1];

    if (isWeixin()) {
        switch (mainPath) {
            // 审核端授权
            case 'review':
                window.location.replace(auth() + '?redirectUri=2')
                break;
                // 用户端授权
            case 'user':
                window.location.replace(auth() + '?redirectUri=1')
                break;
                // 师傅授权
            case 'service':
                window.location.replace(auth() + '?redirectUri=3')
                break;
        }
    } else {
        router.replace({
            name: 'ReviewLogin'
        })
    }
}