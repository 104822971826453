/*
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2021-01-02 14:07:29
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-02 14:08:03
 */
export default function is_weixn() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}