/*
 * @Descripttion: 通用接口
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-09 21:53:45
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-17 21:43:24
 */
import request from "../request"
import {
    HTTP
} from "../config"

// sdk
export const sdk = function (data) {
    return request.post('/wx/js-sdk', data)
}

// 授权
export const auth = function () {
    return HTTP + '/api/wx/auth'
}

// 登陆
export const login = function (data) {
    return request.post('/user/login', data)
}

// 重置密码
export const resetPwd = function (data) {
    return request.post('/user/reset', data)
}

// 发送短信
export const sendSms = function (data) {
    return request.post('/other/sendSms', data)
}

// 注册
export const register = function (data) {
    return request.post('/user/registered', data)
}

// 上传文件
export const upload = function (data) {
    return request.post('/upload/img', data)
}

// 用户信息
export const userInfo = function () {
    return request.get('/user/info')
}

// 报价单配置
export const orderConfig = function () {
    return request.get('/other/getWorkQuotationInfo')
}

// 获取支付信息
export const getPayInfo = function () {
    return request.get('/other/getPayInfo')
}

// 报修类型
export const repairType = function () {
    return request.get('/other/typeRepair')
}