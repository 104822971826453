/*
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 14:35:09
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-08-23 22:31:53
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import setConfig from "./utils/setWxJsConfig"
import wx from "weixin-js-sdk";
import Vconsole from "vconsole";
import {
  ImagePreview
} from "vant";
import {
  DEBUG
} from "./utils/config"

if (DEBUG) new Vconsole();

Vue.directive("number-input", {
  componentUpdated(el) {
    let value = el.value;
    if (value === '') value = 0
    if (isNaN(value)) value = 0
    value = parseFloat(el.value.replace(/[^\d.]/g, ''))
    el.value = value
  }
})

Vue.prototype.$wx = wx;
Vue.prototype.$previewImgs = function (imgs) {
  ImagePreview({
    images: imgs,
    closeable: true
  })
}

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  let title = to.meta.title || '奕侨';
  window.document.title = title;

  // return next();

  if (IsPC()) return window.location.href = 'https://yq.sh-yiqiao.com/'

  if (!checkTokenType(to.path)) {
    window.localStorage.setItem('token', '')
  }
  // 存在token
  if (to.query.token) {
    window.localStorage.setItem('token', to.query.token);
    setTokenType(to.path)
    if (!parseInt(to.query.isUser)) {
      if (to.name !== 'UserRepair') {
        return next({
          path: to.path + '/login'
        })
      }
    }
  }

  next()
})

router.afterEach(() => {
  setConfig()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// 设置token类型
function setTokenType(path) {
  let tokenType = 'user'
  if (path.includes('/service')) tokenType = 'service';
  if (path.includes('/review')) tokenType = 'review'
  window.localStorage.setItem('tokenType', tokenType)
}

// 检查token类型
function checkTokenType(path) {
  let tokenType = window.localStorage.getItem('tokenType');
  if (!tokenType) return true;
  if (path.includes(tokenType)) return true;
  return false;
}


function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone",
    "SymbianOS", "Windows Phone",
    "iPad", "iPod"
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}