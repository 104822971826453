/*
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-09 21:41:38
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-06 21:42:39
 */

import {
    sdk as setSdk
} from "./api/utils"

import isIos from "./checkDevice"

import {
    DEBUG
} from "../utils/config"

import wx from "weixin-js-sdk"

import isWeixin from "./checkWxBrowser"

const href = window.location.href;

const jsApiList = ['chooseImage', 'previewImage', 'uploadImage', 'getLocalImgData']

const setConfig = function () {
    if (!isWeixin()) return false
    setSdk({
        uri: isIos() ? href : window.location.href.split("#")[0],
    }).then(res => {
        wx.config({
            debug: DEBUG,
            ...res.data.data,
            jsApiList
        })
    })
}

export default setConfig