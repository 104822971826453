/*
 * @Descripttion: 配置文件
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-09 21:47:01
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-26 14:23:42
 */

export const HTTP = 'https://sh-yiqiao.com'
export const DEBUG = false