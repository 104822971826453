/*
 * @Descripttion: 检查是否ios设备
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-10 22:43:33
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-10 22:43:56
 */
export default function isIos() {
    var isIphone = navigator.userAgent.includes('iPhone')
    var isIpad = navigator.userAgent.includes('iPad')
    return isIphone || isIpad
}