/*
 * @Descripttion:
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-09 21:37:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-02-24 22:01:21
 */
import axios from "axios";
import { HTTP, DEBUG } from "./config";
import getAuth from "./getAuth";
import isWeixin from "./checkWxBrowser";

const myRequest = axios.create({
  baseURL: HTTP + "/api"
});

// 请求拦截
myRequest.interceptors.request.use(
  function(config) {
    let token = window.localStorage.getItem("token");
    if (isWeixin()) {
      if (!token) return getAuth();
    }
    config.headers["AccessToken"] = token;
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

// 响应拦截
myRequest.interceptors.response.use(
  function(response) {
    if (DEBUG) console.log(response.config.url, "---------->", response);

    // token失效
    if (response.data.code === 301 || response.data.code === 303)
      return getAuth();

    if (response.data.code !== 200) return Promise.reject(response);

    return response;
  },
  function(err) {
    return Promise.reject(err);
  }
);

export default myRequest;
